const isProduction = process.env.NODE_ENV === 'production';

export module Logger {
    export function log(message: string, ...args: any[]) {
        if (!isProduction) {
            if (args.length === 0) {
                console.log(message)
            }
            else {
                console.log(message, args)
            }
        }
    }
    export function warn(message: string, ...args: any[]) {
        if (!isProduction) {
            if (args.length === 0) {
                console.warn(message)
            }
            else {
                console.warn(message, args)
            }
        }
    }
    export function error(err: any) {
        if (!isProduction) {
            console.error(err)
        }
    }
}
