<template>
  <div>
    <div
      class="page-header clear-filter page-header-small"
      filter-color="st__green"
    >
      <parallax
        class="page-header-image"
        style="background-image: url('/img/bg/bakgrundsbild.jpg');"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">
            <span>Välkommen till Kajsas </span>Svamp&shy;tramp<span>!</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <a name="about"></a>
      <div class="container">
        <div class="team">
          <div class="row justify-content-md-center">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="/img/kajsa.jpg"
                  alt=""
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">
                  <span class="title">Svampkonsulent</span><br>
                  Kajsa Raab
                </h4>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="section-story-overview overview__top">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container image-left" :style="randomImageBgStyle('topleft')">
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "Over the span of the satellite record, Arctic sea ice has
                  been declining significantly, while sea ice in the
                  Antarctichas increased very slightly"
                  <br />
                  <br />
                  <small>-NOAA</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div class="image-container" :style="randomImageBgStyle('^topimages')"></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div class="image-container image-right hidden-xs" :style="randomImageBgStyle('topright')"></div>
              <h3>
                So what does the new record for the lowest level of winter ice
                actually mean
              </h3>
              
              <p>
                The Arctic Ocean freezes every winter and much of the sea-ice
                then thaws every summer, and that process will continue whatever
                happens with climate change. Even if the Arctic continues to be
                one of the fastest-warming regions of the world, it will always
                be plunged into bitterly cold polar dark every winter. And
                year-by-year, for all kinds of natural reasons, there’s huge
                variety of the state of the ice.
              </p>
              <p>
                For a start, it does not automatically follow that a record
                amount of ice will melt this summer. More important for
                determining the size of the annual thaw is the state of the
                weather as the midnight sun approaches and temperatures rise.
                But over the more than 30 years of satellite records, scientists
                have observed a clear pattern of decline, decade-by-decade.
              </p>
              <p>
                The Arctic Ocean freezes every winter and much of the sea-ice
                then thaws every summer, and that process will continue whatever
                happens with climate change. Even if the Arctic continues to be
                one of the fastest-warming regions of the world, it will always
                be plunged into bitterly cold polar dark every winter. And
                year-by-year, for all kinds of natural reasons, there’s huge
                variety of the state of the ice.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">@svamptramp.se</h2>
        <p class="description">Fyll i och skicka in kontaktformuläret.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                @click="handleSubmit"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";

var imagesUsedArray = new Array();

export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      images: [
        {
          url: "img/unsplash/kalineri-qaUoTVTI6Cc-unsplash.jpg",
          keywords: ["light", "white", "mushroom-chart"],
        },
        {
          url: "img/unsplash/andrew-ridley-6KCS---7jbc-unsplash.jpg",
          keywords: ["light", "basket"],
        },
        {
          url: "img/unsplash/sergiu-nista-X-b_LL5iuRE-unsplash.jpg",
          keywords: ["dark", "chanterelle"],
        },
        {
          url: "img/unsplash/lucie-hosova-rMNKAQaOlLQ-unsplash.jpg",
          keywords: ["dark", "basket", "grass"],
        },
        {
          url: "img/unsplash/natasha-arefyeva-SJ69mbG3O1I-unsplash.jpg",
          keywords: ["dark", "basket", "grass"],
        },
        {
          url: "img/unsplash/alin-andersen-u3o9-1UmRvY-unsplash.jpg",
          keywords: ["dark", "forest", "topright", 'topimages'],
        },
        {
          url: "img/unsplash/jasper-graetsch-EI8Sg3qdrxY-unsplash.jpg",
          keywords: ["light", "forest", "topleft", 'topimages'],
        },
      ],
    };
  },
  methods: {
    /**
     * Get a random image name. Pass a keyword to get from a specific tag.
     * @param keyword A keyword to use for filtering images.
     * @param noDuplicates Bool to indicate if duplicate usagest should be allowed.
     */
    getRandomImageUrl(keyword = "all", noDuplicates = true) {
      const keywordComparisor = (source, target) => {
        switch (target[0]) {
          case '^': // not
            return ! source.some((k) => k === target.substring(1))
          default:
            return source.some((k) => k === target)
        }
      }
      let imagesToUse = new Array();
      if (this.images.some((i) => keywordComparisor(i.keywords, keyword))) {
        imagesToUse = this.images
          .filter((i) => keywordComparisor(i.keywords, keyword))
          .map((i) => i.url);
        if (noDuplicates) {
          imagesToUse = imagesToUse.filter(
            (iUrl) => !imagesUsedArray.includes(iUrl)
          );
        }
      }
      if (imagesToUse.length === 0) {
        imagesToUse = this.images.map((i) => i.url);
        if (noDuplicates) {
          imagesToUse = imagesToUse.filter(
            (iUrl) => !imagesUsedArray.includes(iUrl)
          );
        }
        if (imagesToUse.length === 0) {
          imagesToUse = this.images.map((i) => i.url);
        }
      }
      const rand = Math.floor(Math.random() * imagesToUse.length);
      return imagesToUse[rand];
    },
    /**
     * Get background-image for css inline style, with a random image based on the keyword.
     * @param keyword Keyword to use to filter images.
     * @param addToUsedArray Add the image to an array of used images so we don't get duplicates.
     */
    randomImageBgStyle(keyword = "all", addToUsedArray = true) {
      const imgName = this.getRandomImageUrl(keyword);
      if (addToUsedArray) {
        imagesUsedArray.push(imgName);
      }
      return { "background-image": `url('${imgName}')` };
    },
    /**
     * Handle form submit.
     */
    async handleSubmit() {
      const response = await fetch('/sm.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.form)
      });
      if (response.ok) {
        console.log('Form submitted ok!'); // TODO: replace with confirmation box!
        this.form = {
          firstName: '',
          email: '',
          message: '',
        };
      } else {
        console.error('Form submission failed: ', response.status);
      }
    },
  },
  computed: {},
  mounted() {
    var section = this.$router.currentRoute.hash.replace('#/', '');
    this.Logger.log(`Mounted. Scroll to ${section}`)
    if (section) {
      this.$nextTick(() => window.document.getElementById(section).scrollIntoView());
    }
  },
};
</script>
<style>
@media (max-width: 768px) {
  h1.title {
    font-size: 3em;
  }
  .hidden-xs {
    display: none;
  }
}
h1.title {
  font-family: "Chango", sans-serif;
  font-weight: 400;
  font-style: normal;
}
a[name='about'] {
  position: relative;
  top: -100px;
}
h4.title > span.title {
  font-size: 1rem;
}
.title > span {
  opacity: 0.75;
}
.overview__top {
  padding-top: 0;
}
.section-story-overview.overview__top .image-container {
  border-radius: 1rem;
}
</style>
