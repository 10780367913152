import Vue from 'vue'
import { Logger } from '@/helpers/logger'


export default {
    install: () => {
        Vue.prototype.Logger = Logger
//        Vue.Logger = Logger // TODO: fix this!
    }
}
